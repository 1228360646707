import React from 'react';
import { Box, Select, MenuItem, Button, Typography } from '@mui/material';
import { SvgIcon } from '@mui/material';

const PlusIcon = (props) => (
    <SvgIcon {...props}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 6.66663V13.3333M13.3333 9.99996H6.66663M15 1.66663H4.99996C3.15901 1.66663 1.66663 3.15901 1.66663 4.99996V15C1.66663 16.8409 3.15901 18.3333 4.99996 18.3333H15C16.8409 18.3333 18.3333 16.8409 18.3333 15V4.99996C18.3333 3.15901 16.8409 1.66663 15 1.66663Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);

const CustomArrowIcon = (props) => (
    <SvgIcon {...props}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00012 10L12.0001 14L17.0001 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);

const FilterSelect = ({ placeholder }) => (
    <Select
        displayEmpty
        IconComponent={CustomArrowIcon} // Используем кастомную стрелку
        defaultValue=""
        sx={{
            width: '116px',
            height: '36px',
            borderRadius: '10px',
            backgroundColor: '#FFFFFF',
            color: '#151813',
            border: '1px solid #E2E4E7',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '18px',
            padding: 0,
            '& .MuiSelect-select': {
                padding: '8px 16px',
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#151813', // Цвет текста
                fontSize: '14px', // Размер шрифта
                fontWeight: 400, // Вес текста
                lineHeight: '18px', // Межстрочный интервал
            },
            '& .MuiSelect-icon': {
                color: '#A2A1A8',
                transition: 'color 0.3s ease', // Добавляем плавный переход для цвета стрелки
            },
            '&:hover': {
                borderColor: '#599D15',
            },
            '&.Mui-focused': {
                backgroundColor: '#599D154D',
                borderColor: '#599D15',
                color: '#599D15',
                '& .MuiSelect-icon': {
                    color: '#599D15', // Цвет стрелки при фокусе
                },
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        }}
    >
        <MenuItem value="" disabled>
            {placeholder}
        </MenuItem>
        <MenuItem value="option1">Option 1</MenuItem>
        <MenuItem value="option2">Option 2</MenuItem>
    </Select>
);

const FilterBar = ({ handleOpen }) => { // Принимаем функцию для открытия
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: '1px solid #e0e0e0' }}>
            <Box sx={{ display: 'flex', gap: '19px', alignItems: 'center' }}>
                <FilterSelect placeholder="Вакансии" />
                <FilterSelect placeholder="Рекрутер" />
                <FilterSelect placeholder="Отдел" />
                <FilterSelect placeholder="Регион" />

                <Typography
                    sx={{
                        color: '#ADB5BD',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '18px',
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    }}
                    onClick={() => console.log("Сбросить фильтр")}
                >
                    Сбросить фильтр
                </Typography>
            </Box>

            <Button
                variant="contained"
                startIcon={<PlusIcon />}
                onClick={handleOpen} // Добавляем обработчик нажатия на кнопку
                sx={{
                    height: '44px',
                    backgroundColor: '#599D15',
                    borderRadius: '8px',
                    '&:hover': {
                        backgroundColor: '#4A8B12',
                    },
                }}
            >
                Создать вакансию
            </Button>
        </Box>
    );
};


export default FilterBar;
