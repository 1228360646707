import React, { useState } from 'react';
import { Container, Box, Typography, Button, Paper, IconButton, Tab, Tabs, Menu, MenuItem, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchVacancyById } from '../../services/vacancyApi';
import editIcon from '../../assets/icons/edit.svg';
import moreIcon from '../../assets/icons/moreIcon.svg';
import DescriptionTab from '../../components/vacancy/DescriptionTab';
import PublicationTab from '../../components/vacancy/PublicationTab';
import depIcon from '../../assets/icons/dep.svg';
import locationIcon from '../../assets/icons/location.svg';
import OverviewTab from '../../components/vacancy/OverviewTab';

const VacancyDetail = () => {
    const { id: vacancyId } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    // Запрос данных вакансии
    const { data: vacancy, isLoading, error } = useQuery(['vacancy', vacancyId], () => fetchVacancyById(vacancyId));

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography color="error">Ошибка при загрузке данных вакансии.</Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
            <Paper
                elevation={0}
                sx={{
                    flex: 1,
                    borderRadius: 2,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #e0e0e0',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(90vh - 25px)',
                }}
            >
                <div style={{
                    padding: '32px 24px 0 24px',
                }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#16151C',
                            }}
                        >
                            {vacancy.name}
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Button
                                variant="outlined"
                                startIcon={<Box component="img" src={editIcon} alt="Редактировать" sx={{ width: 20, height: 20 }} />}
                                sx={{
                                    color: '#599D15',
                                    borderColor: '#599D15',
                                    borderRadius: '8px',
                                    paddingY: '12px',
                                    paddingX: '16px',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    gap: '8px',
                                    textTransform: 'none',
                                    height: '44px',
                                    '&:hover': {
                                        borderColor: '#599D15',
                                        backgroundColor: 'rgba(89, 157, 21, 0.1)',
                                    },
                                }}
                            >
                                Редактировать вакансию
                            </Button>
                            <IconButton
                                onClick={handleMenuOpen}
                                sx={{
                                    width: '44px',
                                    height: '44px',
                                    borderRadius: '8px',
                                    border: '1px solid #599D15',
                                    padding: '8px',
                                    color: '#599D15',
                                    '&:hover': {
                                        backgroundColor: 'rgba(89, 157, 21, 0.1)',
                                    },
                                }}
                            >
                                <Box component="img" src={moreIcon} alt="Больше" sx={{ width: '40px', height: '40px' }} />
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                <MenuItem onClick={handleMenuClose}>Действие 1</MenuItem>
                                <MenuItem onClick={handleMenuClose}>Действие 2</MenuItem>
                            </Menu>
                        </Box>
                    </Box>

                    <Box display="flex" gap={2} mt={1} color="#A2A1A8">
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box component="img" src={depIcon} alt="Отдел" sx={{ width: 24, height: 24 }} />
                            <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
                                {vacancy.department_name}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box component="img" src={locationIcon} alt="Локация" sx={{ width: 24, height: 24 }} />
                            <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
                                {vacancy.city_name}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
                                Дата создания: {new Date(vacancy.created_at).toLocaleDateString()}
                            </Typography>
                        </Box>
                    </Box>

                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        sx={{
                            mt: 3,
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#599D15',
                                height: '3px',
                                borderTopLeftRadius: '2px',
                                borderTopRightRadius: '2px',
                            },
                        }}
                        TabIndicatorProps={{
                            style: { transform: 'translateY(0px)' },
                        }}
                    >
                        <Tab
                            icon={
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse
                                        cx="12"
                                        cy="17.5"
                                        rx="7"
                                        ry="3.5"
                                        fill="none"
                                        stroke={activeTab === 0 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinejoin="round"
                                    />
                                    <circle
                                        cx="12"
                                        cy="7"
                                        r="4"
                                        fill="none"
                                        stroke={activeTab === 0 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            }
                            iconPosition="start"
                            label={<Typography color={activeTab === 0 ? '#599D15' : '#ADB5BD'}>Обзор</Typography>}
                            sx={{
                                textTransform: 'none',
                                minWidth: 100,
                                minHeight: 41,
                                padding: '6px 16px',
                            }}
                        />

                        <Tab
                            icon={
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8 6V5C8 3.34315 9.34315 2 11 2H13C14.6569 2 16 3.34315 16 5V6M2 10.3475C2 10.3475 5.11804 12.4244 9.97767 12.9109M22 10.3475C22 10.3475 18.882 12.4244 14.0223 12.9109M6 22H18C20.2091 22 22 20.2091 22 18V10C22 7.79086 20.2091 6 18 6H6C3.79086 6 2 7.79086 2 10V18C2 20.2091 3.79086 22 6 22Z"
                                        fill="#fff"
                                        stroke={activeTab === 1 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M14 12.16V13.16C14 13.17 14 13.17 14 13.18C14 14.27 13.99 15.16 12 15.16C10.02 15.16 10 14.28 10 13.19V12.16C10 11.16 10 11.16 11 11.16H13C14 11.16 14 11.16 14 12.16Z"
                                        fill="none"
                                        stroke={activeTab === 1 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            }
                            iconPosition="start"
                            label={<Typography color={activeTab === 1 ? '#599D15' : '#ADB5BD'}>Описание</Typography>}
                            sx={{
                                textTransform: 'none',
                                minWidth: 100,
                                minHeight: 41,
                                padding: '6px 16px',
                            }}
                        />

                        <Tab
                            icon={
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 6C4 3.79086 5.79086 2 8 2H15.3431C16.404 2 17.4214 2.42143 18.1716 3.17157L20.8284 5.82843C21.5786 6.57857 22 7.59599 22 8.65685V18C22 20.2091 20.2091 22 18 22H8C5.79086 22 4 20.2091 4 18V6Z"
                                        fill="none"
                                        stroke={activeTab === 2 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M9 7L17 7"
                                        stroke={activeTab === 2 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M9 12H17"
                                        stroke={activeTab === 2 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M9 17H13"
                                        stroke={activeTab === 2 ? "#599D15" : "#ADB5BD"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            }
                            iconPosition="start"
                            label={<Typography color={activeTab === 2 ? '#599D15' : '#ADB5BD'}>Публикация</Typography>}
                            sx={{
                                textTransform: 'none',
                                minWidth: 100,
                                minHeight: 41,
                                padding: '6px 16px',
                            }}
                        />
                    </Tabs>
                    <Box sx={{ borderBottom: '1px solid #E6E6E6', width: '100%' }} />
                </div>

                {activeTab === 0 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <OverviewTab stages={vacancy.stages} />
                    </Box>
                )}
                {activeTab === 1 && <span style={{
                    padding: '0 24px',
                }}><DescriptionTab vacancy={vacancy} /></span>}
                {activeTab === 2 && <span style={{
                    padding: '0 24px',
                }}><PublicationTab /></span>}
            </Paper>
        </Container >
    );
};

export default VacancyDetail;
