// components/main/Header.js
import React from 'react';
import { Container, Typography, Box, InputBase, IconButton, Select, MenuItem } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/notificationIcon.svg';

const CustomArrowIcon = () => (
    <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00012 10L12.0001 14L17.0001 10" stroke="#151813" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

const Header = () => {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Левая часть с заголовком и подзаголовком */}
            <Box>
                <Typography
                    variant="h4"
                    sx={{
                        color: '#16151C',
                        fontSize: '24px',
                        lineHeight: '28px',
                        fontWeight: 600
                    }}
                >
                    Вакансии
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        color: '#A2A1A8',
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 400
                    }}
                >
                    Показать все вакансии
                </Typography>
            </Box>

            {/* Правая часть с полем поиска, иконкой уведомлений и аккаунтом */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        width: '464px',
                        height: '40px',
                        border: '1px solid #F8F9F9',
                        gap: '10px'
                    }}
                >
                    <SearchIcon style={{ color: '#ADB5BD', width: '20px', height: '20px' }} />
                    <InputBase
                        placeholder="Поиск"
                        sx={{
                            flex: 1,
                            color: '#151813',
                            '::placeholder': { color: '#ADB5BD' }
                        }}
                    />
                </Box>

                <IconButton
                    sx={{
                        color: '#A2A1A8',
                        backgroundColor: '#FFFFFF',
                        padding: '8px',
                        borderRadius: '8px',
                        '&:hover': { backgroundColor: '#F0F0F0' }
                    }}
                >
                    <NotificationIcon />
                </IconButton>

                <Select
                    IconComponent={CustomArrowIcon}
                    defaultValue="Qural-Saiman"
                    sx={{
                        backgroundColor: '#FFFFFF',
                        width: '144px', // Фиксируем ширину на 144px
                        height: '40px',
                        borderRadius: '8px',
                        padding: '0 8px', // Уменьшаем отступы
                        color: '#151813',
                        border: 'none',
                        boxShadow: 'none',
                        outline: 'none',
                        overflow: 'hidden', // Предотвращаем увеличение компонента
                        whiteSpace: 'nowrap', // Отключаем перенос текста
                        textOverflow: 'ellipsis', // Добавляем троеточие, если текст не помещается
                        '& .MuiSelect-select': {
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#151813',
                            display: 'flex', // Устанавливаем flex, чтобы текст и иконка были на одной линии
                            alignItems: 'center'
                        },
                        '& fieldset': { border: 'none' }
                    }}
                >
                    <MenuItem
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#151813'
                        }}
                        value="Qural-Saiman"
                    >
                        Qural-Saiman
                    </MenuItem>
                    <MenuItem
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#151813'
                        }}
                        value="Logout"
                    >
                        Выйти
                    </MenuItem>
                </Select>
            </Box>
        </Container>
    );
};

export default Header;
