// hooks/useLogin.js
import { useMutation } from '@tanstack/react-query';
import { login } from '../services/authApi';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
    const navigate = useNavigate();

    return useMutation(login, {
        onSuccess: (data) => {
            const { access_token, privacy_accepted } = data.data;
            localStorage.setItem('access_token', access_token);

            if (!privacy_accepted) {
                navigate('/new_password'); // Перенаправление на страницу смены пароля
            } else {
                navigate('/select_account'); // Перенаправление на страницу выбора аккаунта
            }
        },
        onError: (error) => {
            console.error('Ошибка авторизации', error);
        },
    });
};
