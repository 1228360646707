import React, { useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Modal,
    Radio,
    FormControlLabel,
    Paper,
    Divider,
    IconButton,
} from '@mui/material';
import closeIcon from '../../assets/icons/closeIcon.svg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};

const CreateVacancyModal = ({ open, handleClose }) => {
    const [selectedOption, setSelectedOption] = useState('manual');

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Paper sx={style}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" component="h2" sx={{ fontWeight: 600, fontSize: 20, lineHeight: '30px' }}>
                        Создать вакансию
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <img src={closeIcon} alt="Close" style={{ width: '20px', height: '20px' }} />
                    </IconButton>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Typography sx={{ mt: 2, fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}>
                    Выберите способ
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={selectedOption === 'manual'}
                                onChange={handleRadioChange}
                                value="manual"
                                sx={{
                                    color: '#599D15',
                                    '&.Mui-checked': {
                                        color: '#599D15',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        width: 24,
                                        height: 24,
                                    },
                                }}
                            />
                        }
                        label={<Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>Вручную</Typography>}
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={selectedOption === 'headhunter'}
                                onChange={handleRadioChange}
                                value="headhunter"
                                sx={{
                                    color: '#599D15',
                                    '&.Mui-checked': {
                                        color: '#599D15',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        width: 24,
                                        height: 24,
                                    },
                                }}
                            />
                        }
                        label={<Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>Из HeadHunter</Typography>}
                    />
                </Box>
                <Box sx={{ display: 'flex', mt: 3 }}>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                            marginRight: 2,
                            borderColor: '#599D15',
                            color: '#599D15',
                            flex: 1,
                            height: '44px',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: 'rgba(89, 157, 21, 0.1)', // light green on hover
                            },

                        }}
                    >
                        Отменить
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#599D15',
                            color: 'white',
                            flex: 1,
                            height: '44px',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: '#4A8512', // dark green on hover
                            },
                        }}
                    >
                        Создать
                    </Button>
                </Box>
            </Paper>
        </Modal>
    );
};

export default CreateVacancyModal;
