import React, { useState } from 'react';
import { Container, Typography, Box, Select, MenuItem, Paper, Pagination } from '@mui/material';
import FilterBar from '../../components/vacancy/FilterBar';
import VacancyTable from '../../components/vacancy/VacancyTable';
import CreateVacancyModal from '../../components/vacancy/CreateVacancyModal';
import { useQuery } from '@tanstack/react-query';
import { fetchVacancies } from '../../services/vacancyApi';

const VacanciesPage = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [open, setOpen] = useState(false); // Состояние для управления модальным окном
    const { data: vacancies, isLoading, error } = useQuery(['vacancies'], fetchVacancies);

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
            <Paper elevation={0} sx={{
                flex: 1,
                p: 1,
                borderRadius: 2,
                backgroundColor: '#FFFFFF',
                border: '1px solid #e0e0e0',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'calc(90vh - 25px)',
            }}>
                <FilterBar handleOpen={handleClickOpen} />

                <Box sx={{ flex: 1, overflowY: 'auto', padding: '0' }}>
                    {isLoading ? (
                        <Typography>Загрузка...</Typography>
                    ) : error ? (
                        <Typography color="error">Ошибка при загрузке вакансий</Typography>
                    ) : (
                        <VacancyTable vacancies={vacancies} />
                    )}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: '#A2A1A8', fontWeight: '300' }}>Показать</Typography>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            sx={{
                                mx: 1,
                                color: '#6E6E6E',
                                borderColor: '#E0E0E0',
                                height: '46px',
                                width: '76px'
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>

                        <Typography variant="body2" sx={{ color: '#A2A1A8', fontWeight: 300 }}>результатов на странице</Typography>
                    </Box>
                    <Pagination count={4} variant="outlined" shape="rounded" sx={{ button: { color: '#6E6E6E', borderColor: '#E0E0E0' } }} />
                </Box>
            </Paper >

            <CreateVacancyModal open={open} handleClose={handleClose} />
        </Container >
    );
};

export default VacanciesPage;
