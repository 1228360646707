// src/pages/NotFoundPage.js
import React from 'react';
import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
            <Typography variant="h4" color="error" gutterBottom>
                404 - Страница не найдена
            </Typography>
            <Typography variant="body1">
                К сожалению, страница, которую вы ищете, не существует.
            </Typography>
            <Link to="/" style={{ marginTop: '20px', color: '#599D15' }}>
                Вернуться на главную
            </Link>
        </Box>
    );
};

export default NotFoundPage;
