// src/services/vacancyApi.js
import axiosClassic from "../api/interceptors";

export const fetchVacancies = async () => {
    const companyId = localStorage.getItem('company_id'); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies`);
    return response.data.data; // Возвращаем только данные о вакансиях
};

// Функция для получения конкретной вакансии по ID
export const fetchVacancyById = async (vacancyId) => {
    const companyId = localStorage.getItem('company_id'); // Получаем ID компании из localStorage
    if (!companyId) throw new Error("ID компании не найден");

    const response = await axiosClassic.get(`/api/company/${companyId}/vacancies/${vacancyId}`);
    return response.data.data; // Предполагаем, что данные вакансии находятся в `data.data`
};
