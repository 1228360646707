import React, { useState } from 'react';
import { Box, OutlinedInput, Typography, Button, Paper, Link, Snackbar, Alert } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom'; // Импорт useNavigate
import { setNewPassword } from '../../services/authApi';
import successIcon from '../../assets/icons/successIcon.svg';

const NewPasswordPage = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate(); // Инициализация useNavigate

    const mutation = useMutation(setNewPassword, {
        onSuccess: () => {
            setOpenSnackbar(true); // Показать уведомление об успешной смене пароля

            // Через 1 секунду (после закрытия Snackbar) перенаправляем на нужную страницу
            setTimeout(() => {
                navigate('/'); // Перенаправление на главную страницу или нужную страницу
            }, 1000);
        },
        onError: (error) => {
            console.error('Ошибка смены пароля', error);
        },
    });

    const handleSave = () => {
        if (password === confirmPassword) {
            mutation.mutate(password); // Отправляем запрос на установку нового пароля
        } else {
            alert('Пароли не совпадают');
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: '#F4F5F7',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    color: '#599D15',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    mb: 4,
                    position: 'absolute',
                    top: '10%',
                }}
            >
                tapHR
            </Typography>

            <Paper
                elevation={3}
                sx={{
                    width: '508px',
                    padding: '40px',
                    borderRadius: '12px',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: '600', mb: 1 }}>
                    Новый пароль
                </Typography>
                <Typography variant="body2" sx={{ color: '#A2A1A8', mb: 4 }}>
                    Придумайте новый пароль для входа
                </Typography>

                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
                        <Typography sx={{ color: '#151813', fontSize: '16px', lineHeight: '20px', fontWeight: 500, mb: 0.5 }}>
                            Пароль
                        </Typography>
                        <OutlinedInput
                            placeholder="Введите новый пароль"
                            fullWidth
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{
                                backgroundColor: '#F8F9F9',
                                borderRadius: '4px',
                                padding: '8px',
                                height: '52px',
                                width: '100%',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#E2E4E7',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#E2E4E7',
                                },
                            }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2 }}>
                        <Typography sx={{ color: '#151813', fontSize: '16px', lineHeight: '20px', fontWeight: 500, mb: 0.5 }}>
                            Подтвердите пароль
                        </Typography>
                        <OutlinedInput
                            placeholder="Повторно введите новый пароль"
                            fullWidth
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            sx={{
                                backgroundColor: '#F8F9F9',
                                borderRadius: '4px',
                                padding: '8px',
                                height: '52px',
                                width: '100%',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#E2E4E7',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#E2E4E7',
                                },
                            }}
                        />
                    </Box>
                </Box>

                <Typography variant="body2" sx={{ color: '#A2A1A8', mb: 3 }}>
                    Нажимая кнопку, вы соглашаетесь с{' '}
                    <Link href="#" underline="hover" sx={{ color: '#599D15' }}>
                        пользовательским соглашением
                    </Link>{' '}
                    и{' '}
                    <Link href="#" underline="hover" sx={{ color: '#599D15' }}>
                        политикой конфиденциальности
                    </Link>
                </Typography>

                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSave}
                    sx={{
                        backgroundColor: '#599D15',
                        padding: '16px',
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: '#4A8B12',
                        },
                    }}
                    disabled={mutation.isLoading}
                >
                    Сохранить
                </Button>
            </Paper>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={1000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    icon={<img src={successIcon} alt="success icon" style={{ marginRight: '8px' }} />}
                    onClose={handleCloseSnackbar}
                    severity="success"
                    sx={{
                        backgroundColor: '#E6F4EA',
                        color: '#599D15',
                        border: '1px solid #599D15',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Пароль успешно обновлен
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default NewPasswordPage;
