import React from 'react';
import { TableRow, TableCell, Checkbox, IconButton } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';


const VacancyTableRow = ({ id, position, region, department, responses, recruiter, date }) => {
    const navigate = useNavigate();

    const handleRowClick = () => {
        navigate(`/vacancy/${id}`);
    };

    return (
        <TableRow hover onClick={handleRowClick} sx={{ cursor: 'pointer' }}>
            <TableCell padding="checkbox">
                <Checkbox />
            </TableCell>
            <TableCell>
                <WorkOutlineIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                {position}
            </TableCell>
            <TableCell>{region}</TableCell>
            <TableCell>{department}</TableCell>
            <TableCell>{responses}</TableCell>
            <TableCell>{recruiter}</TableCell>
            <TableCell>{date}</TableCell>
            <TableCell align="right">
                <IconButton size="small">
                    <MoreVertIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default VacancyTableRow;
