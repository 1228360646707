import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    MenuItem,
    Paper,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VacancyEditor from '../../components/vacancy/VacancyEditor';
import { SvgIcon } from '@mui/material';
import ArrowUpIcon from '../../assets/icons/arrowUp.svg';
import ArrowDownIcon from '../../assets/icons/arrowDown.svg';
import DisabledDeleteIcon from '../../assets/icons/disabledDeleteIcon.svg';
import ActiveDeleteIcon from '../../assets/icons/activeDeleteIcon.svg';
import closeIcon from '../../assets/icons/closeIcon.svg';


const steps = ['Основная информация', 'Собеседование', 'Публикация'];


const PlusIcon = (props) => (
    <SvgIcon {...props}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 6.66663V13.3333M13.3333 9.99996H6.66663M15 1.66663H4.99996C3.15901 1.66663 1.66663 3.15901 1.66663 4.99996V15C1.66663 16.8409 3.15901 18.3333 4.99996 18.3333H15C16.8409 18.3333 18.3333 16.8409 18.3333 15V4.99996C18.3333 3.15901 16.8409 1.66663 15 1.66663Z" stroke="#599D15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);


const LabeledInput = ({ label, value, onChange, width, ...props }) => {
    return (
        <Box sx={{ mb: 3, width }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#151813', mb: 1 }}>
                {label}
            </Typography>
            <TextField
                fullWidth
                value={value}
                onChange={onChange}
                variant="outlined"
                {...props}
                sx={{
                    backgroundColor: '#F8F9F9',
                    borderRadius: '8px',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#E0E0E0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#BDBDBD',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#599D15',
                        },
                    },
                }}
            />
        </Box>
    );
};

const CustomStepper = ({ steps, activeStep }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 4 }}>
            {steps.map((label, index) => (
                <React.Fragment key={label}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '124px' }}>
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                                backgroundColor:
                                    activeStep > index ? '#73C18D' : activeStep === index ? '#599D15' : '#E0E0E0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: activeStep > index ? '#fff' : activeStep === index ? '#fff' : '#000',
                                fontWeight: 400,
                                fontSize: '24px',
                                zIndex: 1,
                            }}
                        >
                            {activeStep > index ? (
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M18.4933 6.93502C18.8053 7.20743 18.8374 7.68122 18.565 7.99325L10.7079 16.9933C10.5654 17.1564 10.3594 17.25 10.1429 17.25C9.9263 17.25 9.72031 17.1564 9.57788 16.9933L6.43502 13.3933C6.16261 13.0812 6.19473 12.6074 6.50677 12.335C6.8188 12.0626 7.29259 12.0947 7.565 12.4068L10.1429 15.3596L17.435 7.00677C17.7074 6.69473 18.1812 6.66261 18.4933 6.93502Z"
                                        fill="white"
                                    />
                                </svg>
                            ) : (
                                index + 1
                            )}
                        </Box>
                        <Typography
                            sx={{
                                color: activeStep > index ? '#14AE5C' : activeStep === index ? '#599D15' : '#A2A1A8',
                                fontWeight: 400,
                                fontSize: '14px',
                                textAlign: 'center',
                                mt: 1,
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>

                    {index < steps.length - 1 && (
                        <Box
                            sx={{
                                flex: 1,
                                height: 2,
                                backgroundColor: activeStep > index ? '#73C18D' : '#E0E0E0',
                                mx: 2,
                                position: 'relative',
                                top: 21,
                            }}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

const CreateVacancyPage = () => {
    const [activeStep, setActiveStep] = useState(2);
    const [salaryFrom, setSalaryFrom] = useState('');
    const [salaryTo, setSalaryTo] = useState('');
    const [currency, setCurrency] = useState('тг');
    const [experience, setExperience] = useState('noExperience');
    const [employmentType, setEmploymentType] = useState('fullTime');
    const [workSchedule, setWorkSchedule] = useState('fullDay');
    const [taxType, setTaxType] = useState('beforeTax');
    const [text, setText] = useState('');
    const [stages, setStages] = useState([
        { id: 1, name: 'Новый' },
        { id: 2, name: 'Интервью' },
        { id: 3, name: 'Тестовое задание' },
        { id: 4, name: 'Финальное интервью' },
        { id: 5, name: 'Принял оффер' }
    ]);

    const formData = {
        title: 'Chief technical officer',
        city: 'Астана',
        salary: 'от 200 000 до 500 000 Т до вычета налогов',
        experience: 'Более 6 лет',
        employmentType: 'Полная занятость',
        schedule: 'Полный день',
        responsibilities: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        requirements: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        conditions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...',
        stages: [
            { name: 'Новый' },
            { name: 'Интервью' },
            { name: 'Тестовое задание' },
            { name: 'Финальное интервью' },
            { name: 'Принял оффер' }
        ]
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNext = () => setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
    const handleBack = () => setActiveStep((prev) => Math.max(prev - 1, 0));


    const handleCreate = () => {

    }


    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" component="h1" sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px' }}>
                                Основная информация
                            </Typography>
                            <Button variant="outlined" sx={{ color: '#FF3B30', borderColor: '#FFA8A6', height: '44px', borderRadius: '8px' }}>
                                Отменить
                            </Button>
                        </Box>
                        <Divider sx={{ mt: 2, mb: 4 }} />

                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <LabeledInput
                                label="Название *"
                                value=""
                                onChange={() => { }}
                                placeholder="Введите название вакансии"
                                width="356px"
                            />
                            <LabeledInput
                                label="Город *"
                                value=""
                                onChange={() => { }}
                                placeholder="Выберите один"
                                width="356px"
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 2, mt: 2, height: '84px' }}>
                            <LabeledInput
                                label="Заработная плата от"
                                value={salaryFrom}
                                onChange={(e) => setSalaryFrom(e.target.value)}
                                placeholder="от"
                                width="356px"
                            />
                            <LabeledInput
                                label="до"
                                value={salaryTo}
                                onChange={(e) => setSalaryTo(e.target.value)}
                                placeholder="до"
                                width="356px"
                            />
                            <Box sx={{ width: '94px' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '20px', color: '#151813', mb: 1 }}>
                                    Валюта
                                </Typography>
                                <TextField
                                    select
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: '#F8F9F9',
                                        borderRadius: '8px',
                                        width: '100%',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#E0E0E0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#BDBDBD',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#599D15',
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value="тг">тг</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                </TextField>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
                            <RadioGroup row value={taxType} onChange={(e) => setTaxType(e.target.value)}>
                                <FormControlLabel
                                    value="beforeTax"
                                    control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />}
                                    label="До вычета налогов"
                                />
                                <FormControlLabel
                                    value="afterTax"
                                    control={<Radio sx={{ color: '#A2A1A8', '&.Mui-checked': { color: '#599D15' } }} />}
                                    label="На руки"
                                />
                            </RadioGroup>
                        </Box>

                        <div style={{ marginTop: '36px' }}>
                            <Typography sx={{ mt: 3 }}>Описание</Typography>
                            <p style={{ color: '#ADB5BD', fontSize: '14px', lineHeight: '18px', fontWeight: 400, marginTop: '8px' }}>Описание вакансии не может быть менее 150 символов</p>
                            <VacancyEditor text={text} setText={setText} />
                        </div>


                        <Typography sx={{ mt: 3 }}>Опыт работы *</Typography>
                        <RadioGroup value={experience} onChange={(e) => setExperience(e.target.value)} sx={{ mt: 1 }}>
                            <FormControlLabel value="noExperience" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Нет опыта" />
                            <FormControlLabel value="1-3" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="От 1 года до 3 лет" />
                            <FormControlLabel value="3-6" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="От 3 до 6 лет" />
                            <FormControlLabel value="moreThan6" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Более 6 лет" />
                        </RadioGroup>

                        <Typography sx={{ mt: 3 }}>Тип занятости *</Typography>
                        <RadioGroup value={employmentType} onChange={(e) => setEmploymentType(e.target.value)} sx={{ mt: 1 }}>
                            <FormControlLabel value="fullTime" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Полная занятость" />
                            <FormControlLabel value="partTime" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Частичная занятость" />
                            <FormControlLabel value="contract" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Проектная работа или разовое задание" />
                        </RadioGroup>

                        <Typography sx={{ mt: 3 }}>График работы *</Typography>
                        <RadioGroup value={workSchedule} onChange={(e) => setWorkSchedule(e.target.value)} sx={{ mt: 1 }}>
                            <FormControlLabel value="fullDay" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Полный день" />
                            <FormControlLabel value="shift" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Сменный график" />
                            <FormControlLabel value="flexible" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Гибкий график" />
                            <FormControlLabel value="remote" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Удаленная работа" />
                            <FormControlLabel value="rotational" control={<Radio sx={{ color: '#599D15', '&.Mui-checked': { color: '#599D15' } }} />} label="Вахтовый метод" />
                        </RadioGroup>
                    </>
                );
            case 1:
                return (
                    <div>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 500, fontSize: 20, lineHeight: '24px' }}>Этапы собеседования</Typography>
                            <Button
                                variant="outlined"
                                startIcon={<PlusIcon />}
                                onClick={handleOpen}
                                sx={{ color: '#599D15', borderColor: '#599D15', width: '164px', height: '44px', borderRadius: '8px', fontSize: '14px', fontWeight: 400, textTransform: 'none' }}
                            >
                                Добавить этап
                            </Button>
                        </Box>
                        <Typography sx={{ color: '#A2A1A8', fontSize: '14px', marginBottom: '16px' }}>Название этапа</Typography>
                        <Box>
                            {stages.map((stage) => (
                                <Box
                                    key={stage.id}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderBottom: '1px solid #E0E0E0',
                                        py: 2
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <img src={ArrowUpIcon} alt="Move up" style={{ cursor: 'pointer' }} />
                                        <img src={ArrowDownIcon} alt="Move down" style={{ cursor: 'pointer' }} />
                                        <Typography sx={{ marginLeft: '8px' }}>{stage.name}</Typography>
                                    </Box>
                                    {stage.id !== 1 && stage.id !== 5 ? (
                                        <Button
                                            sx={{

                                                minWidth: 'unset',
                                                background: '#FFF3F2',
                                                padding: '4px',
                                                border: '1px solid #FF7E7A',
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <img src={ActiveDeleteIcon} alt="disabled delete" style={{ cursor: 'pointer' }} />
                                        </Button>
                                    ) : (
                                        <Button
                                            sx={{

                                                minWidth: 'unset',
                                                background: '#FFF3F2',
                                                padding: '4px',
                                                borderRadius: '8px'
                                            }}
                                        >
                                            <img src={DisabledDeleteIcon} alt="disabled delete" style={{ cursor: 'pointer' }} />
                                        </Button>
                                    )}

                                </Box>
                            ))}
                        </Box>
                    </div>

                );
            case 2:
                return (
                    <>
                        {/* Заголовок Шага 3 */}

                        {/* Accordion для "Посмотреть вакансию" */}
                        <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{
                                    backgroundColor: '#E8EFE4',
                                    minHeight: '56px', // Фиксируем минимальную высоту
                                    height: '56px', // Фиксируем общую высоту
                                    boxShadow: 'none', // Убираем тень
                                    '&.Mui-expanded': {
                                        minHeight: '56px', // Фиксируем высоту при открытии
                                        height: '56px',
                                    },
                                }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#599D15', fontSize: '18px' }}>
                                    Посмотреть вакансию
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: '1fr 1fr', mb: 3 }}>
                                    <TextField label="Название" value={formData.title} InputProps={{ readOnly: true }} />
                                    <TextField label="Город" value={formData.city} InputProps={{ readOnly: true }} />
                                    <TextField label="Заработная плата" value={formData.salary} InputProps={{ readOnly: true }} />
                                    <TextField label="Опыт работы" value={formData.experience} InputProps={{ readOnly: true }} />
                                    <TextField label="Тип занятости" value={formData.employmentType} InputProps={{ readOnly: true }} />
                                    <TextField label="График работы" value={formData.schedule} InputProps={{ readOnly: true }} />
                                </Box>
                                <Divider />
                                <Typography sx={{ mt: 2, fontWeight: 500 }}>Обязанности</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={formData.responsibilities}
                                    InputProps={{ readOnly: true }}
                                    sx={{ my: 1 }}
                                />
                                <Typography sx={{ mt: 2, fontWeight: 500 }}>Требования</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={formData.requirements}
                                    InputProps={{ readOnly: true }}
                                    sx={{ my: 1 }}
                                />
                                <Typography sx={{ mt: 2, fontWeight: 500 }}>Условия</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={formData.conditions}
                                    InputProps={{ readOnly: true }}
                                    sx={{ my: 1 }}
                                />
                            </AccordionDetails>
                        </Accordion>

                        {/* Accordion для "Посмотреть этапы" */}
                        <Accordion defaultExpanded sx={{ mt: 3, boxShadow: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{
                                    backgroundColor: '#E8EFE4',
                                    minHeight: '56px', // Фиксируем минимальную высоту
                                    height: '56px', // Фиксируем общую высоту
                                    boxShadow: 'none', // Убираем тень
                                    '&.Mui-expanded': {
                                        minHeight: '56px', // Фиксируем высоту при открытии
                                        height: '56px',
                                    },
                                }}
                            >
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#599D15', fontSize: '18px' }}>
                                    Посмотреть этапы
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {formData.stages.map((stage, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #E0E0E0',
                                            py: 1,
                                        }}
                                    >
                                        <Typography>{stage.name}</Typography>
                                    </Box>
                                ))}
                            </AccordionDetails>
                        </Accordion>


                        {/* Кнопки "Назад" и "Создать" */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3, gap: 2 }}>
                            <Button
                                variant="outlined"
                                sx={{ marginRight: 2, height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', color: '#599D15', borderColor: '#599D15', fontSize: '16px', fontWeight: '400' }}
                                onClick={handleBack}
                            >
                                Назад
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#599D15', color: 'white', height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', fontSize: '16px', fontWeight: '400' }}
                                onClick={handleCreate} // Здесь обработчик для создания вакансии
                            >
                                Создать
                            </Button>
                        </Box>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
            <CustomStepper steps={steps} activeStep={activeStep} />

            <Paper
                elevation={0}
                sx={{
                    p: 4,
                    borderRadius: 2,
                    backgroundColor: '#FFFFFF',
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'calc(90vh - 25px)',
                }}
                style={activeStep === 2 && { background: 'transparent', padding: 0 }}
            >
                {renderStepContent(activeStep)}
            </Paper>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" PaperProps={{ sx: { borderRadius: '12px', width: '397px', padding: '24px' } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0, marginBottom: '12px' }}>
                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: 20 }}>
                        Добавить этап
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ color: '#000' }}>
                        <img src={closeIcon} alt="Close" style={{ width: '16px', height: '16px' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ padding: 0 }}>
                    <TextField
                        fullWidth
                        select
                        label="Выберите этап"
                        variant="outlined"
                        sx={{
                            backgroundColor: '#F8F9F9',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#E0E0E0',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#BDBDBD',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#599D15',
                                },
                            },
                            mt: 2,
                        }}
                    >
                        {/* Добавьте ваши опции этапов здесь */}
                    </TextField>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', mt: 3, padding: 0 }}>
                    <Button onClick={handleClose} variant="outlined" sx={{ flex: 1, color: '#599D15', borderColor: '#599D15', height: '44px', borderRadius: '8px', boxShadow: 'none' }}>
                        Отменить
                    </Button>
                    <Button onClick={handleClose} variant="contained" sx={{ flex: 1, backgroundColor: '#599D15', color: '#fff', height: '44px', borderRadius: '8px', boxShadow: 'none' }}>
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>

            {activeStep < steps.length - 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button
                        variant="outlined"
                        sx={{ marginRight: 2, height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', color: '#599D15', borderColor: '#599D15', fontSize: '16px', fontWeight: '400' }}
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        Назад
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: '#599D15', color: 'white', height: '56px', boxShadow: 'none', borderRadius: '8px', width: '202px', fontSize: '16px', fontWeight: '400' }}
                        onClick={handleNext}
                    >
                        Перейти к {activeStep + 2} шагу
                    </Button>
                </Box>
            )}
        </Container>
    );
};

export default CreateVacancyPage;
