import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import VacanciesPage from './pages/vacancy/VacanciesPage';
import VacancyDetail from './pages/vacancy/VacancyDetail';
import NavigationBar from './components/main/NavigationBar';
import Header from './components/main/Header';
import { Box, CircularProgress } from '@mui/material';
import SignInPage from './pages/auth/SignInPage';
import NewPasswordPage from './pages/auth/NewPasswordPage';
import NotFoundPage from './pages/NotFoundPage';
import ProtectedRoute from './components/main/ProtectedRoute';
import AccountSelectionPage from './pages/auth/AccountSelectionPage'; // Страница выбора аккаунта
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-react-kanban/styles/material.css';
import CreateVacancyPage from './pages/vacancy/CreateVacancyPage';

function PublicRoute({ element: Component, ...rest }) {
  const isAuthenticated = Boolean(localStorage.getItem('access_token'));
  return isAuthenticated ? <Navigate to="/" replace /> : <Component {...rest} />;
}

function App() {
  const location = useLocation();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const isAuthenticated = Boolean(localStorage.getItem('access_token'));
      const hasManagerId = Boolean(localStorage.getItem('manager_id'));

      if (!isAuthenticated && location.pathname !== '/login' && location.pathname !== '/new_password') {
        window.location.href = '/login';
      } else if (isAuthenticated && !hasManagerId && location.pathname !== '/select_account') {
        window.location.href = '/select_account';
      } else {
        setIsCheckingAuth(false);
      }
    };

    checkAuth();
  }, [location.pathname]);

  if (isCheckingAuth) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const isAuthPage = ['/login', '/new_password', '/select_account'].includes(location.pathname);
  const isNotFoundPage = !location.pathname.match(/^\/(vacancy\/\d+|vacancy\/create|login|new_password|select_account)?$/);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {!isAuthPage && !isNotFoundPage && <NavigationBar />}

      <Box sx={{ flex: 1, overflowY: 'auto', background: "#f8f8f8" }}>
        {!isAuthPage && !isNotFoundPage && <Header />}

        <Routes>
          <Route path="/login" element={<PublicRoute element={SignInPage} />} />
          <Route path="/new_password" element={<PublicRoute element={NewPasswordPage} />} />
          <Route path="/select_account" element={<AccountSelectionPage />} />

          <Route path="/" element={<ProtectedRoute element={VacanciesPage} />} />
          <Route path="/vacancy/:id" element={<ProtectedRoute element={VacancyDetail} />} />
          <Route path="/vacancy/create" element={<ProtectedRoute element={CreateVacancyPage} />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Box>
    </Box>
  );
}


function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
