// NavigationBar.js
import React, { useState } from 'react';
import { Box, Typography, List, Collapse } from '@mui/material';
import NavigationItem from './NavigationItem';
import DashboardIcon from "../../assets/icons/Icons.svg";
import DepartmentsIcon from "../../assets/icons/departmentsIcon.svg";
import CandidatesIcon from "../../assets/icons/candidatesIcon.svg";
import SettingsIcon from "../../assets/icons/settingsIcon.svg";
import IntegrationIcon from "../../assets/icons/integrationIcon.svg";
import PatternsIcon from "../../assets/icons/patternsIcon.svg";
import ReportsIcon from "../../assets/icons/reportsIcon.svg";
import StagesIcon from "../../assets/icons/stagesIcon.svg";
import TestsIcon from "../../assets/icons/testsIcon.svg";
import vacancyIcon from "../../assets/icons/vacancyIcon.svg";
import ArrowBottomIcon from "../../assets/icons/ArrowBottomIcon.svg";

const NavigationBar = () => {
    const [activeItem, setActiveItem] = useState('Дэшборд');
    const [settingsOpen, setSettingsOpen] = useState(false);

    const handleItemClick = (label) => {
        setActiveItem(label);
        console.log(`Navigating to ${label}`);
    };

    const handleSettingsClick = () => {
        setSettingsOpen(!settingsOpen);
        setActiveItem('Настройки');
    };

    return (
        <Box sx={{ width: 292, backgroundColor: '#fff', borderRight: '1px solid #e0e0e0', height: '100vh', padding: '0 36px' }}>
            <Typography variant="h5" sx={{ padding: 2, color: '#6BA14A', fontWeight: 'bold', fontSize: '36px', lineHeight: '43px' }}>
                tapHR
            </Typography>
            <List style={{ background: '#fff' }}>
                <NavigationItem icon={DashboardIcon} label="Дэшборд" isActive={activeItem === 'Дэшборд'} onClick={() => handleItemClick('Дэшборд')} />
                <NavigationItem icon={vacancyIcon} label="Вакансии" isActive={activeItem === 'Вакансии'} onClick={() => handleItemClick('Вакансии')} />
                <NavigationItem icon={CandidatesIcon} label="Кандидаты" isActive={activeItem === 'Кандидаты'} onClick={() => handleItemClick('Кандидаты')} />
                <NavigationItem icon={TestsIcon} label="Тесты" isActive={activeItem === 'Тесты'} onClick={() => handleItemClick('Тесты')} />
                <NavigationItem icon={ReportsIcon} label="Отчеты" isActive={activeItem === 'Отчеты'} onClick={() => handleItemClick('Отчеты')} />

                {/* Настройки с аккордеоном */}
                <NavigationItem 
                    icon={SettingsIcon} 
                    label="Настройки" 
                    isActive={activeItem === 'Настройки'} 
                    onClick={handleSettingsClick}
                    arrow={<img src={ArrowBottomIcon} alt="arrowBottom" style={{ transform: settingsOpen ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }} />}
                />

                {/* Вложенные элементы под "Настройки" */}
                <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ paddingLeft: 2 }}>
                        <NavigationItem icon={IntegrationIcon} label="Пользователи" isActive={activeItem === 'Пользователи'} onClick={() => handleItemClick('Пользователи')} />
                        <NavigationItem icon={DepartmentsIcon} label="Отделы" isActive={activeItem === 'Отделы'} onClick={() => handleItemClick('Отделы')} />
                        <NavigationItem icon={StagesIcon} label="Этапы отбора" isActive={activeItem === 'Этапы отбора'} onClick={() => handleItemClick('Этапы отбора')} />
                        <NavigationItem icon={PatternsIcon} label="Шаблоны" isActive={activeItem === 'Шаблоны'} onClick={() => handleItemClick('Шаблоны')} />
                        <NavigationItem icon={IntegrationIcon} label="Интеграции" isActive={activeItem === 'Интеграции'} onClick={() => handleItemClick('Интеграции')} />
                        <NavigationItem icon={IntegrationIcon} label="FAQ" isActive={activeItem === 'FAQ'} onClick={() => handleItemClick('FAQ')} />
                    </Box>
                </Collapse>
            </List>
        </Box>
    );
};

export default NavigationBar;
