// services/authApi.js
import axiosClassic from '../api/interceptors';
import { useNavigate } from 'react-router-dom';

// Функция для авторизации
export const login = async (credentials) => {
    const response = await axiosClassic.post('/api/auth/login', credentials);
    const { access_token, refresh_token } = response.data.data;

    // Сохраняем токены в localStorage
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);

    return response.data;
};

// Функция для установки нового пароля
export const setNewPassword = async (newPassword) => {
    const token = localStorage.getItem('access_token'); // Получаем токен из локального хранилища

    const response = await axiosClassic.post(
        '/api/auth/set-new-password',
        { new_password: newPassword },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

    const { access_token, refresh_token } = response.data.data;

    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);

    return response.data;
};
