import React from 'react';
import { Route, Navigate } from 'react-router-dom';

// Проверка авторизации на основе наличия access_token в localStorage
const isAuthenticated = () => {
    return Boolean(localStorage.getItem('access_token'));
};

// Компонент ProtectedRoute для защиты маршрутов
const ProtectedRoute = ({ element: Component, ...rest }) => {
    return isAuthenticated() ? <Component {...rest} /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
