import React, { useEffect } from 'react';
import { Box, Button, Card, Grid2 as Grid, Typography } from '@mui/material'; // Импортируем Grid2
import hhLogo from '../../assets/icons/hh.png';
import habrLogo from '../../assets/icons/habr.png';
import axiosClassic from '../../api/interceptors';
import { useLocation } from 'react-router-dom';


const JobPlatforms = [
    { id: 1, name: "HeadHunter", description: "Биржа вакансий", logo: hhLogo, action: "Подключить", variant: "outlined" },
    { id: 2, name: "Хабр Карьера", description: "Биржа вакансий", logo: habrLogo, action: "Подключить", variant: "outlined" },
];

const sendAuthRequest = async (code, redirectUri, managerId) => {
    try {
        const response = await axiosClassic.post('https://api.taphr.kz/api/hh/auth', {
            code,
            redirect_uri: redirectUri,
            manager_id: managerId,
        });
        console.log('Запрос успешно отправлен:', response.data);
    } catch (error) {
        console.error('Ошибка при отправке запроса:', error);
    }
};

const PublicationTab = () => {
    const location = useLocation();

    const goToHH = () => {
        // const currentUrl = window.location.origin + window.location.pathname;
        if (localStorage.getItem('manager_id')) {
            const currentUrl = 'https://app.taphr.kz' + window.location.pathname + `?manager_id=${localStorage.getItem('manager_id')}`;
            const encodedRedirectUri = encodeURIComponent(`${currentUrl}`);
            console.log(currentUrl)
            console.log(encodedRedirectUri)
            window.location.href = `https://hh.ru/oauth/authorize?response_type=code&client_id=${localStorage.getItem('client_id')}&redirect_uri=${encodedRedirectUri}`;
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // Получаем нужные параметры
        const code = params.get('code');
        const managerId = location.search.split('manager_id=')[1]?.split('&')[0];
        // const redirectUri = `${window.location.origin}${location.pathname}`;
        const redirectUri = `https://app.taphr.kz${location.pathname}?manager_id=${managerId}`;

        console.log(redirectUri)
        console.log(managerId)
        console.log(code)

        if (code && managerId) {
            // Отправляем запрос, если code и manager_id существуют
            sendAuthRequest(code, redirectUri, managerId);
        }
    }, [location]);


    return (
        <Box mt={3}>
            <Grid container spacing={3} justifyContent="flex-start"> {/* Устанавливаем отступы 24px между блоками */}
                {JobPlatforms.map((platform) => (
                    <Grid item xs={12} sm={6} md={4} key={platform.id}>
                        <Card
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 3, // Увеличиваем отступы до 24px (2.5 * 8px)
                                borderRadius: 2,
                                width: '231px', // Установленная ширина карточек
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={2} sx={{ mb: 2 }}>
                                <Box component="img" src={platform.logo} alt={platform.name} sx={{ width: 48, height: 48 }} />
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            color: '#151813',
                                        }}
                                        variant="h5"
                                    >
                                        {platform.name}
                                    </Typography>
                                    <Typography sx={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        color: '#9BA2A9',
                                    }} variant="body2">
                                        {platform.description}
                                    </Typography>
                                </Box>
                            </Box>
                            <Button
                                onClick={goToHH}
                                variant={platform.variant}
                                sx={{
                                    marginTop: 'auto',
                                    color: platform.variant === "outlined" ? "#599D15" : "#FFFFFF",
                                    borderColor: "#599D15",
                                    backgroundColor: platform.variant === "contained" ? "#599D15" : "transparent",
                                    width: '100%',
                                    height: '34px',
                                    borderRadius: '8px',
                                    paddingY: '10px',
                                    paddingX: '16px', // Увеличиваем отступ внутри кнопки
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: platform.variant === "contained" ? "#4A8512" : "rgba(89, 157, 21, 0.1)",
                                    },
                                }}
                            >
                                {platform.action}
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PublicationTab;
