// NavigationItem.js
import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

const NavigationItem = ({ icon, label, isActive, onClick, arrow }) => {
    return (
        <ListItem
            button
            onClick={onClick}
            sx={{
                backgroundColor: isActive ? '#E8EFE4' : 'transparent',
                '&:hover': { backgroundColor: '#f4f4f4' },
                paddingY: 1,
                paddingLeft: 2,
                cursor: 'pointer',
                marginBottom: '16px',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px'
            }}
        >
            <ListItemIcon sx={{ minWidth: 24, marginRight: '16px' }}>
                <img
                    src={icon}
                    alt={`${label} icon`}
                    style={{
                        filter: isActive
                            ? 'invert(38%) sepia(74%) saturate(472%) hue-rotate(66deg) brightness(90%) contrast(83%)'
                            : 'none'
                    }}
                />
            </ListItemIcon>
            <ListItemText
                primary={label}
                primaryTypographyProps={{ fontWeight: isActive ? 'bold' : 'normal', color: isActive ? '#599D15' : '#151813', width: 'fit-content' }}
            />
            {arrow && arrow}
        </ListItem>
    );
};

export default NavigationItem;
