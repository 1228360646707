import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, Menu, MenuItem, Button, Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';

const OverviewTab = ({ stages }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStages, setFilteredStages] = useState(stages);

    const handleSearchChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        const newFilteredStages = stages.map((stage) => ({
            ...stage,
            candidates: stage.candidates.filter((candidate) =>
                candidate.name.toLowerCase().includes(term)
            ),
        }));
        setFilteredStages(newFilteredStages);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 1 }}>
            {/* Поле поиска */}
            <Box
                sx={{
                    width: '464px',
                    my: 3,
                    mx: 3,
                    backgroundColor: '#F8F9F9',
                    borderRadius: '10px',
                    padding: '13px 16px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <SearchIcon style={{ marginRight: '8px' }} />
                <input
                    type="text"
                    placeholder="Поиск"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        color: '#A2A1A8',
                        fontSize: '16px',
                    }}
                />
            </Box>

            {/* Канбан-доска с этапами */}
            <Box
                display="flex"
                gap={2}
                sx={{
                    flex: 1,
                    paddingLeft: '24px',
                    overflowX: 'auto',
                    maxWidth: '100%',
                }}
            >
                {filteredStages.map((stage) => (
                    <Paper
                        key={stage.id}
                        sx={{
                            minWidth: '265px',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: 2,
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            backgroundColor: '#F1F2F3',
                            border: '1px solid #FFFFFF',
                            maxHeight: '468px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: '#151813',
                            }}
                        >
                            {stage.name}{' '}
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    color: '#599D15',
                                }}
                            >
                                {stage.candidates.length}
                            </Typography>
                        </Typography>
                        <Box mt={2} sx={{ overflowY: 'auto', flex: 1 }}>
                            {stage.candidates.map((candidate, index) => (
                                <CandidateCard key={index} candidate={candidate} />
                            ))}
                        </Box>
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};

const CandidateCard = ({ candidate }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSubmenuAnchorEl(null);
    };

    const handleSubmenuOpen = (event) => {
        setSubmenuAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = () => {
        setSubmenuAnchorEl(null);
    };

    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                padding: 2,
                borderRadius: '8px',
                marginBottom: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box>
                <Typography fontWeight="bold">{candidate.name}</Typography>
                <Typography variant="body2" color="#A2A1A8">
                    {candidate.email}
                </Typography>
            </Box>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    '& .MuiPaper-root': {
                        padding: '8px',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                }}
            >
                <MenuItem onClick={handleMenuClose}>
                    <Typography sx={{ fontSize: '14px' }}>Посмотреть кандидата</Typography>
                </MenuItem>
                <MenuItem onMouseEnter={handleSubmenuOpen} onClick={handleSubmenuClose}>
                    <Typography sx={{ fontSize: '14px', color: '#599D15', fontWeight: 'bold' }}>
                        Сменить этап подбора
                    </Typography>
                </MenuItem>
            </Menu>

            {/* Подменю для "Сменить этап подбора" */}
            <Popover
                anchorEl={submenuAnchorEl}
                open={Boolean(submenuAnchorEl)}
                onClose={handleSubmenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        padding: '8px',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                }}
            >
                <MenuItem onClick={handleSubmenuClose}>Интервью</MenuItem>
                <MenuItem onClick={handleSubmenuClose}>Тестирование</MenuItem>
                <MenuItem onClick={handleSubmenuClose}>Принял оффер</MenuItem>
            </Popover>
        </Box>
    );
};

export default OverviewTab;
